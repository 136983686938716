import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Product {
    id: string;
    name: string;
    note: string;
    businessId: string;
    quantity: number;
    minimum: number;
}

export interface ProductState {
    products: Product[];
}

const name = 'product';
const initialState: ProductState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getProducts.fulfilled, (state, action) => {
            state.products = action.payload.data;
        });
    }
});

export const productActions = { ...slice.actions, ...extraActions };
export const productReducer = slice.reducer;

function createInitialState() {
    return {
        products: []
    };
}

function createReducers() {
    return {
        setItems,
        addProduct,
        updateProduct,
        deleteProduct
    };

    function setItems(state: ProductState, action: PayloadAction<{ key: keyof ProductState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addProduct(state: ProductState, action: PayloadAction<Product>) {
        state.products = [...state.products, action.payload];
    }

    function updateProduct(state: ProductState, action: PayloadAction<Product>) {
        const products = [...state.products];
        const index = products.findIndex(product => product.id === action.payload.id);
        if (index >= 0) products.splice(index, 1, action.payload);
        state.products = products;
    }

    function deleteProduct(state: ProductState, action: PayloadAction<Product>) {
        const products = [...state.products];
        const index = products.findIndex(products => products.id === action.payload.id);
        if (index >= 0) products.splice(index, 1);
        state.products = products;
    }
}

function createExtraReducers() {
    return {
        getProducts: getProductsThunk()
    };
    function getProductsThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/products`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
