import { Button } from '@mui/material';
import classes from './Navbar.module.scss';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState, RootState, authActions } from 'store';
import { ArrowBackIos, ExitToApp, KeyboardArrowDown } from '@mui/icons-material';
import { useToggle } from 'hook';
import SendEmail from 'components/sendEmail/SendEmail';
import { history } from 'helpers';
import { useMemo } from 'react';
import { useGetBusiness } from 'hook/useGetBusiness';

const NavBar = () => {
    const { token, user } = useSelector<RootState>(x => x.auth) as AuthState;
    const dispatch = useDispatch();
    const [isMenuOpen, menuHandler] = useToggle();
    const { business } = useGetBusiness();

    const title = useMemo(() => {
        if (!history.location.pathname.includes('store')) return 'Carichi in uscita';
        const businessId = history.location.pathname.split('/store/')[1];
        const bus = business.find(b => b.id === businessId);
        if (!bus) return 'Magazzino';

        return (
            <>
                <ArrowBackIos className={classes.back} onClick={() => history.navigate('/store')} />
                {bus.name}
            </>
        );
    }, [business, history.location.pathname]);

    if (!token) return <></>;

    const logoutHandler = () => {
        dispatch(authActions.logout());
        menuHandler.off();
    };

    return (
        <nav className={classes.navbar}>
            <div className={classes.logo}>{title}</div>
            <ul className={classes['nav-links']}>
                <Button onClick={menuHandler.toggle} className={classes.hamburger} color="inherit" data-value={isMenuOpen}>
                    &#9776;
                </Button>
                <div className={classes.menu}>
                    <li>
                        <NavLink onClick={menuHandler.off} to="/" className={({ isActive }) => (isActive ? classes.active : '')}>
                            <Button color="inherit">Calendario</Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={menuHandler.off}
                            to="/agents/map"
                            className={({ isActive }) => (isActive ? classes.active : '')}
                        >
                            <Button color="inherit">Mappa</Button>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            onClick={menuHandler.off}
                            to="/summary"
                            className={({ isActive }) => (isActive ? classes.active : '')}
                        >
                            <Button color="inherit">Resoconto</Button>
                        </NavLink>
                    </li>
                    {user?.role === 'store' && (
                        <li>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/store"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                <Button color="inherit">Magazzino</Button>
                            </NavLink>
                        </li>
                    )}
                    <li className={classes.dropdown}>
                        <Button
                            color="inherit"
                            type="button"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            IMPOSTAZIONI
                            <KeyboardArrowDown />
                        </Button>
                        <div className={classes.dropdown_content}>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/transporters"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Trasportatori
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/agents/all"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Agenti
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/materials"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Materiali
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/packages"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Confezioni
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/clients"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Clienti
                            </NavLink>
                            <NavLink
                                onClick={menuHandler.off}
                                to="/tickets"
                                className={({ isActive }) => (isActive ? classes.active : '')}
                            >
                                Carichi
                            </NavLink>
                        </div>
                    </li>
                    {user?.role === 'admin' && (
                        <li>
                            <SendEmail />
                        </li>
                    )}
                    <li>
                        <NavLink to="/login" className={({ isActive }) => (isActive ? classes.active : '')}>
                            <Button color="inherit" onClick={logoutHandler}>
                                <ExitToApp />
                            </Button>
                        </NavLink>
                    </li>
                </div>
            </ul>
        </nav>
    );
};

export default NavBar;
