import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { lazy } from 'react';
import PrivateRoute from 'views/privateRoute/PrivateRoute';
import Packages from 'views/packages/Packages';

const Calendar = lazy(() => import('views/calendar/Calendar'));
const Transporters = lazy(() => import('views/transporters/Transporters'));
const Agents = lazy(() => import('views/agents/Agents'));
const AgentsTable = lazy(() => import('views/agents/agentsTable/AgentsTable'));
const Tickets = lazy(() => import('views/tickets/Tickets'));
const Clients = lazy(() => import('views/clients/Clients'));
const Summary = lazy(() => import('views/summary/Summary'));
const Store = lazy(() => import('views/store/Store'));
const Business = lazy(() => import('views/business/Business'));
const Materials = lazy(() => import('views/materials/Materials'));
const Login = lazy(() => import('views/login/Login'));

const Routing = () => {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Calendar />
                    </PrivateRoute>
                }
            />
            <Route
                path="/transporters"
                element={
                    <PrivateRoute>
                        <Transporters />
                    </PrivateRoute>
                }
            />
            <Route
                path="agents"
                element={
                    <PrivateRoute>
                        <Outlet />
                    </PrivateRoute>
                }
            >
                <Route path="all" element={<AgentsTable />} />
                <Route path="map" element={<Agents />} />
            </Route>
            <Route
                path="/clients"
                element={
                    <PrivateRoute>
                        <Clients />
                    </PrivateRoute>
                }
            />
            <Route
                path="/summary"
                element={
                    <PrivateRoute>
                        <Summary />
                    </PrivateRoute>
                }
            />
            <Route
                path="/materials"
                element={
                    <PrivateRoute>
                        <Materials />
                    </PrivateRoute>
                }
            />
            <Route
                path="/packages"
                element={
                    <PrivateRoute>
                        <Packages />
                    </PrivateRoute>
                }
            />
            <Route
                path="/tickets"
                element={
                    <PrivateRoute>
                        <Tickets />
                    </PrivateRoute>
                }
            />
            <Route
                path="/store"
                element={
                    <PrivateRoute>
                        <Business />
                    </PrivateRoute>
                }
            />
            <Route
                path="/store/:business"
                element={
                    <PrivateRoute>
                        <Store />
                    </PrivateRoute>
                }
            />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};
export default Routing;
