import { useDispatch, useSelector } from 'react-redux';
import { RootState, BusinessState, businessActions } from 'store';
import { useMount } from './useMount';

export const useGetBusiness = () => {
    const dispatch = useDispatch();
    const { business } = useSelector<RootState>(x => x.business) as BusinessState;

    useMount(() => {
        if (!business?.length) dispatch(businessActions.getBusiness() as any);
    });

    return { business };
};
