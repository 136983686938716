import { useDispatch, useSelector } from 'react-redux';
import { RootState, ProductState, productActions } from 'store';
import { useMount } from './useMount';

export const useGetProducts = () => {
    const dispatch = useDispatch();
    const { products } = useSelector<RootState>(x => x.products) as ProductState;

    useMount(() => {
        if (!products?.length) dispatch(productActions.getProducts() as any);
    });

    return { products };
};
