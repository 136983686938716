import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { env } from 'environments';

export interface Business {
    id: string;
    name: string;
}

export interface BusinessState {
    business: Business[];
}

const name = 'business';
const initialState: BusinessState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraReducers();
const slice = createSlice({
    name,
    initialState,
    reducers,
    extraReducers: builder => {
        builder.addCase(extraActions.getBusiness.fulfilled, (state, action) => {
            state.business = action.payload.data;
        });
    }
});

export const businessActions = { ...slice.actions, ...extraActions };
export const businessReducer = slice.reducer;

function createInitialState() {
    return {
        business: []
    };
}

function createReducers() {
    return {
        setItems,
        addBusiness,
        updateBusiness,
        deleteBusiness
    };

    function setItems(state: BusinessState, action: PayloadAction<{ key: keyof BusinessState; value: any }>) {
        state[action.payload.key] = action.payload.value;
    }

    function addBusiness(state: BusinessState, action: PayloadAction<Business>) {
        state.business = [...state.business, action.payload];
    }

    function updateBusiness(state: BusinessState, action: PayloadAction<Business>) {
        const business = [...state.business];
        const index = business.findIndex(business => business.id === action.payload.id);
        if (index >= 0) business.splice(index, 1, action.payload);
        state.business = business;
    }

    function deleteBusiness(state: BusinessState, action: PayloadAction<Business>) {
        const business = [...state.business];
        const index = business.findIndex(business => business.id === action.payload.id);
        if (index >= 0) business.splice(index, 1);
        state.business = business;
    }
}

function createExtraReducers() {
    return {
        getBusiness: getBusinessThunk()
    };
    function getBusinessThunk() {
        return createAsyncThunk(
            `${name}`,
            async () =>
                await axios.get(`${env.reactAppSocketUrl}${env.apiUrl}/business`).catch(err => {
                    throw Error(err.response.data.message);
                })
        );
    }
}
